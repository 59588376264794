.LocationName {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding: .4rem .8rem;
  }


.LocationName p,
.LocationName h1 {
  margin: 0px;
}

.LocationName p { 
   font-size: 1.6rem;
   font-weight: 600;
   opacity: .9;
}

.LocationName h1 {
  font-size: 3.2rem;
}