table.Table {
  border-collapse: collapse;
}

/* CLASSES */
.Table .center {
  text-align: center;
}

/* ROWS */
.Table tr:nth-child(even) {
  background: rgba(255, 255, 255, .1);
}

/* CELLS */
.Table td,
.Table th {
  padding: .4rem .8rem;
}
.Table th {
  text-align: left;
}
.Table td.shrink,
.Table th.shrink {
  white-space: nowrap;
  width: 0.1%;
}

/* SVGs */
.Table svg path:last-child {
  fill: var(--secondary);
}