.EtdCounter {
  margin: 0;
  text-align: right;
}
.EtdCounter p,
.EtdCounter h2 {
  margin: 0;
}

.EtdCounter p {
  font-size: 1.2rem;
}

.EtdCounter h2 {
  font-size: 4rem;
}