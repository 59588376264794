.IndexRoute {
  background: var(--primary);
  color: var(--secondary);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.IndexRoute .container {
  border: 1px solid var(--secondary);
  border-radius: 1rem;
  margin: 0 auto;
  max-width: 400px;
  padding: 2rem;
  text-align: center;
}

.IndexRoute h1 {
  margin-top: 0;
}

.IndexRoute a {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2;
}
.IndexRoute a:hover {
  text-decoration: underline;
}